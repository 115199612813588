import React from "react";
import meA33 from "../img/portfolio/old-phone.png";
import { About, Description, Image } from "../styles";
//Framer Motion
import { titleAnim, fade, photoAnim, pageAnimation } from "../animation";
import Wave from "./Wave";
//Animations
import { motion } from "framer-motion";
import styled from "styled-components";

import Mailto from "../components/Mailto";

const ContactMeSection = () => {
  return (
    <About>
      <Description>
        <Title>
          <Hide>
            <motion.h2 variants={titleAnim}>Get in touch.</motion.h2>
          </Hide>
        </Title>
        <Social variants={titleAnim}>
          <a
            href="https://wa.me/+32487379348"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <h2>WhatsApp</h2>
          </a>
        </Social>

        <Social variants={titleAnim}>
          <Mailto
            email="mariel.latella@gmail.com"
            subject="Contact from your portfolio"
            body="Hello, I'm interested in contacting you!"
          >
            <i class="far fa-envelope"></i>
            <h2>Mail me!</h2>
          </Mailto>
        </Social>
        <Social variants={titleAnim}>
          <i class="fab fa-linkedin"></i>
          <a
            href="https://www.linkedin.com/in/mariellatellabarros/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <h2>LinkedIn</h2>
          </a>
        </Social>
      </Description>
      <Image>
        <motion.img
          variants={photoAnim}
          src={meA33}
          alt="Mariel, husband Ben and her two kids: Thomas and Matthias"
        />
      </Image>
      <Wave />
    </About>
  );
};

//Styled Components
const Social = styled(motion.div)`
  display: flex;
  align-items: center;
  h2 {
    margin: 2rem;
  }
`;
const Title = styled.div`
  margin-bottom: 4rem;
  color: black;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  // overflow: hidden;
`;
export default ContactMeSection;
