import React from "react";
//Import Icons

import diaphragm from "../img/diaphragm.svg";
import timer from "../img/timer-flash-line.svg";
import team from "../img/team.svg";
import analytical from "../img/analytical.svg";
import next from "../img/next.svg";
//Styles
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";

//Import Icons: https://dev.to/kevsmss/easiest-way-to-use-icons-in-react-h0o
import { FontAwesome } from "react-icons/fa";
import { Devicons } from "react-icons/di";
import { MaterialDesign } from "react-icons/md";
import { RemixIcons } from "react-icons/ri";

//Import image
import A113 from "../img/me/A113.jpg";

const CompetencesSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>
          <span>Competences</span>
        </h2>
        <Cards>
          <Card>
            <div className="icon">
              <img alt="icon" src={timer} />
              <h3>Result-oriented</h3>
            </div>
            <p>
              Through <span>planning</span>, <span>delegation</span> and
              <span> trust</span> I reach my goals and those of my team. I'm{" "}
              <span>flexible</span>. I simply <span>deliver</span>.
            </p>
          </Card>
          <Card>
            {/* https://spencerauthor.com/can-you-force-collaboration/ */}
            <div className="icon">
              <img alt="icon" src={team} />
              <h3>Cooperation and Collaboration</h3>
            </div>
            <p>
              My <span>extrovert</span> personality and
              <span> experience in customer service</span> allows me to
              <span> engage</span> with people spontaneously. I seak alignment
              as I believe a constructive cooperation and respect leads to{" "}
              <span>healthy </span> and
              <span> productive teams</span>.
            </p>
          </Card>

          <Card>
            <div className="icon">
              <img alt="icon" src={analytical} />
              <h3>Analytical</h3>
            </div>
            <p>
              I make my decisions based on <span>facts</span> and analyze
              situations thoroughly.
            </p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={next} />
              <h3>Ambition - Aspiration</h3>
            </div>
            <p>
              I have a strong desire to achieving success. When I decide I want
              to go for something, I <span>make it happen</span>. A supporting
              wife, a loving mother, a caring friend, What's next? a{" "}
              <span>successful team leader</span>.
            </p>
          </Card>
        </Cards>
      </Description>
      <Image>
        <img alt="me with computer" src={A113} />
      </Image>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 95%;
    text-alignment: "center";
    padding: 2rem 0rem 4rem 0rem;
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  flex-basis: 20rem;
  .icon {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 1rem;
      background: white;
      color: black;
      padding: 1rem;
    }
  }
`;

export default CompetencesSection;
