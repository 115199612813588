import React from "react";
import meA33 from "../img/me/A33.jpg";
import { About, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";
//Link
import { Link } from "react-router-dom";
const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>I work to make</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              my <span>dreams</span> come
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>true.</motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          Building solutions that help others make their lives easier. That's my
          call. Solutions that I discuss and carefully analyse from different
          angles. Solutions that are written with care.
          <p>
            Althouh I have years of work experience in the tourism industry, it
            was only when I worked at Nike Europe that I discovered my true
            passion and also, found my true love. My passion: coding. My true
            love: my family. I took another challenge in my life by learning
            Dutch so I could get my Bachelor. Combining school in a foreign
            language with being a mother is not easy, but thanks to the support
            of my family and my driven personality I have achieved my goal.
            What's <span>next</span>?
          </p>
          When I'm not perfectioning my skills, you'll find me cooking, reading,
          gardening, enjoying my family and trying to learn guitar!
        </motion.p>

        <Link to="/contact">
          <motion.button variants={fade} onClick>
            Contact Me
          </motion.button>
        </Link>
      </Description>
      <Image>
        <motion.img
          variants={photoAnim}
          src={meA33}
          alt="Mariel, husband Ben and her two kids: Thomas and Matthias"
        />
      </Image>
      <Wave />
    </About>
  );
};

//Styled Components

export default AboutSection;
