import React from "react";
// import { whatsapp } from "@fortawesome/free-brands-svg-icons";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim } from "../animation";
import styled from "styled-components";

import Mailto from "../components/Mailto";
import ContactMeSection from "../components/ContactMeSection";

const ContactMe = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      style={{ background: "#fff" }}
    >
      <ContactMeSection></ContactMeSection>
    </ContactStyle>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  // min-height: 90vh;
  width: '100vw',
  height: '100vh'
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

export default ContactMe;
