import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";
//Images
import smiley from "../img/ghost-smile.svg";

const FaqSection = () => {
  const [element, controls] = useScroll();
  return (
    <Faq
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        Fun <span>FACTS</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Steak or Pizza?">
          <div className="answer">
            <p>Mmmm....</p>
            <p>
              My grandparents were Italian and Spanish. Argentina is also known
              for its delicious steaks and asados. Having Italian DNA makes this
              one a very hard question to answer. in Buenos Aires we make the
              best pizzas in the world! You should definitely visit "El
              Cuartito" and "Las Cuartetas"! Incredible tasty traditional
              argentinian pizza!
            </p>
            <p>
              Steak or Pizza? I'd say steak with a delicious argentinean Malbec
              OR italian pizza with a lekker Belgishe bier!
            </p>
            <p>
              Enjoying <span>life.</span>
            </p>
          </div>
        </Toggle>
        <Toggle title="Beach or Mountains?">
          <div className="answer">
            <p>
              This one is an easy one
              <img alt="icon" src={smiley} />
            </p>
            <p>
              I love nature and staying active. Although the last couple of
              years I was rather sedentary, I used to play volleybal in
              tournaments, swim, and I ran the "20km door Brussel" a couple of
              years ago. Having worked at Nike European headquarters has also
              given me the opportunity to stay fit. Since I met my husband,
              skiing on the French Alpes, every year we go skiing once or even
              twice during the winter and hiking during the summer. I currently
              do yoga and go for a walk every day to release stress and enjoy
              nature.
            </p>
            <p>Definitely Mountains!</p>
            <p>
              Being <span>adventurous.</span>
            </p>
          </div>
        </Toggle>
        <Toggle title="Cats or Dogs?">
          <div className="answer">
            <p>
              I've been raised with animals in the house... Dogs, canaries and
              even doves!. I always thought I was a "dog-person", only because
              we genearlly get this sort of question where we need to choose one
              side. But, what if we really like both? Or we don't take the time
              to explore? I like to get my own impression about life. We now
              have a cat at home and I love it!
            </p>
            <p>Cats AND Dogs!</p>
            <p>
              Staying <span>curious.</span>
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled(About)`
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default FaqSection;
