import React from "react";
//Import Icons
import diaphragm from "../img/diaphragm.svg";
import analytical from "../img/analytical.svg";
import java from "../img/java.svg";
import csharp from "../img/csharp.svg";
import php from "../img/php.svg";
import iconJS from "../img/icon-js.svg";
import iconReact from "../img/icon-react.svg";
import iconAngular from "../img/icon-angular.svg";
import iconRedux from "../img/icon-redux.svg";
import iconTypeScript from "../img/icon-typescript.svg";
import iconBootstrap from "../img/icon-bootstrap.svg";
import iconDocker from "../img/icon-docker.svg";
import iconSql from "../img/icon-mySql.svg";
import iconMongoDb from "../img/icon-mongoDb.svg";
//Styles
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";

//Import Icons: https://dev.to/kevsmss/easiest-way-to-use-icons-in-react-h0o
import { FontAwesome } from "react-icons/fa";
import { Devicons } from "react-icons/di";
import { MaterialDesign } from "react-icons/md";
import { RemixIcons } from "react-icons/ri";

//Images
import A88 from "../img/me/A88.jpg";
const SkillsSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>
          <span>Skills</span>
        </h2>
        <Cards>
          <Card>
            <div className="icon">
              <img alt="icon" src={java} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={csharp} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={php} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconJS} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconReact} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconAngular} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconRedux} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconTypeScript} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconBootstrap} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconDocker} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconSql} />
            </div>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={iconMongoDb} />
            </div>
          </Card>
        </Cards>
      </Description>
      <Image>
        <img alt="camera" src={A88} />
      </Image>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  flex-basis: 20rem;
  margin-bottom: 4rem;
  .icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    h3 {
      margin-left: 1rem;
      background: white;
      color: black;
      padding: 1rem;
    }
  }
`;

export default SkillsSection;
