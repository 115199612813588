import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HorizontalLine, { ColoredLine } from "../components/HorizontalLine";
//Images
import athlete from "../img/athlete-small.png";
import theracer from "../img/theracer-small.png";
import goodtimes from "../img/goodtimes-small.png";
import projectweek from "../img/portfolio/projectweek.jpg";
import popSessions from "../img/portfolio/pop-sessions.jpg";
import pepper from "../img/portfolio/pepper.png";
//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>

      {/* Activities */}
      <Movie>
        <motion.h2 variants={fade}>Project week</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/projectweek">
          <Hide>
            <motion.img
              variants={photoAnim}
              src={projectweek}
              alt="introspection in the dessert"
            />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element} variants={fade} animate={controls} initial="hidden">
        <h2>POP Sessions</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/pop-sessions">
          <img src={popSessions} alt="building together" />
        </Link>
      </Movie>
      <Movie
        ref={element2}
        variants={fade}
        animate={controls2}
        initial="hidden"
      >
        <h2>Seminary AI: Artificial Intelligence with AWS Pretrained Models</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/seminary">
          <img src={pepper} alt="goodtimes" />
        </Link>
      </Movie>

      <h1>Other activities</h1>

      <h2>Seminary Flutter</h2>
      <p>
        Calin Cosma from Tobania introduced the Flutter UI toolkit based on the
        new programming language created by Google, Dart. The common elements
        occurring in different languages were shared (getter, setters,
        collections, etc.), as well as its own particularities. The seminar was
        concluded with a practical demo.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Seminary Docker</h2>
      <p>
        Jesse Verbruggen from Axxes showed the power of Docker by creating an
        entirely new application and having it run publicly in Azure; usually
        with just the command line. In addition, best practices were shown
        around applications running in Docker during the demo, such as
        automatically restarting an application after x number of failed tests.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Seminary IT Security & Data Privacy awareness</h2>
      <p>
        Ordina presented an interactive information session on various current
        Information Security & Privacy topics (security risks, data leaks
        /hackings, GDPR, tips & tricks, ...), applicable in both professional
        and private life.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Innovation: AI route</h2>
      <p>
        The AI Innovation Route was a three-day seminar where three companies
        introduced the world of AI and provided activities where the students
        could see it in action.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Innovation: Hackathon Sint-Oda</h2>
      <p>
        It is difficult to find a suitable application for people with severe
        multiple disabilities. This hackathon was organized to develop
        prototypes for the residents of Sint-Oda. The theme was already
        established: a prototype game about the seasons of the year.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Personal development: Portfolio</h2>
      <p>
        Being able to reflect on yourself is unfortunately a skill that we are
        not used to in this modern and hurried world. The portfolio is an
        activity that allows the student to stop for a moment to view his / her
        evolution, locate and evaluate actions / activities. In this way we
        become aware of who we are and how we can contribute to society with the
        skills we have learned.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Study engangement: Studdy-buddy</h2>
      <p>
        Being a study buddy is one way to showcase the X factor in each of us.
        During this activity, I was able to help my colleague understand Java
        Essentials a little bit better than before.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Study engangement: IT for moms</h2>
      <p>
        More and more we see that women dare to enter the Informatica world. It
        is important to motivate and embrace this new generation and offer
        support as diviersity makes a difference. IT voor Mama’s is a community
        that enables highly motivated student moms to search for support and
        share their experiences.
      </p>
      <HorizontalLine color="#23D997" />

      <h2>Extra activity: Building this web-portfolio</h2>
      <p>Lorem Ipsum.</p>
      <HorizontalLine color="#23D997" />

      <ScrollTop />
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background: #23d997;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #8effa0;
`;

export default OurWork;
