//Import Images
import athlete from "./img/athlete-small.png";
import goodtimes from "./img/goodtimes-small.png";
import theracer from "./img/theracer-small.png";
import athlete2 from "./img/athlete2.png";
import goodtimes2 from "./img/good-times2.jpg";
import theracer2 from "./img/the-racer2.jpg";
import rocks from "./img/portfolio/rocks.jpg";
import motivation from "./img/portfolio/motivation.png";
import ai from "./img/portfolio/ai.jpg";
import projectweek2 from "./img/portfolio/projectweek-2.jpg";
import aiCode from "./img/portfolio/ai-Code.png";
import negFeedback from "./img/portfolio/neg-feedback.png";
import motivation2 from "./img/portfolio/way-out-is-through.jpg";

export const MovieState = () => {
  return [
    {
      title: "Project week",
      mainImg: rocks,
      secondaryImg: projectweek2,
      url: "/work/projectweek",
      awards: [
        {
          // Thalento assesement + kernkwadrant. Who am I?
          title: "Introspection",
          description:
            "Knowing who you are is the first step towards working efficiently in team. I clearly know in which ways I can contribute to a team thanks to the Thalento assessment and the 'kernkwadrant'.",
        },
        {
          // Deep analysis of research topic, main question and subset. Structure.
          title: "Research",
          description:
            "Writing meaningfully is an art. During this workshop I learned the importance of asking the right questions and to structure a research paper.",
        },
        {
          // Hackathon. LinkedIn. Job Event.
          title: "Exposure",
          description:
            "Getting out of the comfort zone is hard. Thanks to the activities such as the Job Event and Hackathon I improved my professional profile on LinkedIn, got in touch with IT-companies for the first time, demystified preconceptions I had about Hackathons and did pair-programming for the first time. Awesome.",
        },
      ],
    },
    {
      title: "POP Sessions",
      mainImg: negFeedback,
      url: "/work/pop-sessions",
      secondaryImg: motivation,
      awards: [
        {
          title: "Feedback session",
          description:
            "How can I become a better communicator? Giving and receiving feedback is a sensitive matter. When not given correctly, it can lead to misconceptions and create friction in the team. In this session I reinforced the knowledge I had and I even use this technique at home!",
        },
        {
          title: "Planning - Eisenhower matrix",
          description:
            " “By Failing to prepare, you are preparing to fail.” – Benjamin Franklin. Having a plan means acting purposely. You actions are no longer random. You are in charge. In this workshop I reinforced the idea that planning is key to success by focusing on my goal and using the Eisenhower matrix to plan my activities accordingly.",
        },
        {
          title: "Stay motivated",
          description:
            "We can do things because we're told to do so... Or we can do them because WE want to. Intrinsic motivation is the opposite of instant gratification, and it is very powerful. Understanding our passion gives us the energy we need when things get tough. ",
        },
      ],
    },
    {
      title: "AI with AWS Pretrained Models",
      mainImg: ai,
      url: "/work/seminary",
      secondaryImg: aiCode,
      awards: [
        {
          title: "New technologies",
          description:
            "I'm curious. Siri, self driving cars, Netflix... AI is EVERYWHERE! This seminary gave me an insight in AI and learned that it is not as hard as I thought it would be.",
        },
        {
          title: "Chatbot",
          description:
            "During this workshop I learned to program a simple chatbot using AWS Web services (code below). Moreover, this seminary opened the door to explore AWS. I then discovered how to host this website under the AWS Amplify service.",
        },
        {
          title: "Networking",
          description:
            "The seminaries are a valuable way to connect students with companies is a much more relaxed environment. This gave me the possibility to learn about A.C.A. IT-Solutions and kept implementing this strategy with the following events.",
        },
      ],
    },
  ];
};
